
require("datatables.net");
require('datatables.net-bs4');
require('datatables.net-responsive');
require('datatables.net-buttons');
require('datatables.net-searchbuilder');
require('datatables.net-searchpanes');
require('datatables.net-select');
require('datatables.net-select-bs4');

    var INDEX = 0;
    $("#chat-submit").click(function(e) {
        e.preventDefault();
        var msg = $("#chat-input").val();
        if(msg.trim() == ''){
            return false;
        }
        generate_message(msg, 'self');
        var buttons = [
            {
                name: 'Existing User',
                value: 'existing'
            },
            {
                name: 'New User',
                value: 'new'
            }
        ];
        setTimeout(function() {
            generate_message(msg, 'user');
        }, 1000)

    })

    function display_welcome_msg(){
        $(".chat-logs").html("");
        if(gon['page_welcome']!=undefined) {
            setTimeout(function () {
                generate_message(gon['page_welcome'], 'user');
            }, 300)
        }

        setTimeout(function() {
            generate_message("I'm Vatrenas Virtual Assistant. Feel free to ask me a question or select one of the topic below!", 'user');
        }, 800)

        if(gon['product_image']!=undefined) {
            if(gon['page_name'] == 'product'){
                setTimeout(function() {
                    generate_message("Product: "+gon['product_name'], 'user');
                }, 1300)
                setTimeout(function() {
                    generate_message("<img src='"+gon['product_image']+"'>", 'user');
                }, 1600)
                setTimeout(function() {
                    for(var i=0;i<gon['page_questions'].length;i++){
                        generate_message("<a href='#'>"+gon['page_questions'][i]+"</a>", 'user');
                    }
                }, 2300)
            }
        }else{
            if(gon['page_questions']!=undefined) {
                setTimeout(function () {
                    for (var i = 0; i < gon['page_questions'].length; i++) {
                        generate_message("<a href='#'>" + gon['page_questions'][i] + "</a>", 'user');
                    }
                }, 1300)
            }else{
                setTimeout(function () {
                    generate_message("<a href='#'>Go to our FAQ page</a>", 'user');
                    generate_message("<a href='#'>Visit contact us page</a>", 'user');
                    generate_message("<a href='#'>Visit careers page</a>", 'user');
                }, 1300)
            }
        }


    }

    function generate_message(msg, type) {
        var avatar= ""
        if(type == "user"){
            avatar = "https://vatrenas.com/images/logo-sm.png"
        }else{
            avatar = "https://vatrenas.com/images/logo-self.png"
        }
        INDEX++;
        var str="";
        str += "<div id='cm-msg-"+INDEX+"' class=\"chat-msg "+type+"\">";
        str += "          <span class=\"msg-avatar\">";
        str += "            <img src=\""+avatar+"\">";
        str += "          <\/span>";
        str += "          <div class=\"cm-msg-text\">";
        str += msg;
        str += "          <\/div>";
        str += "        <\/div>";
        $(".chat-logs").append(str);
        $("#cm-msg-"+INDEX).hide().fadeIn(300);
        if(type == 'self'){
            $("#chat-input").val('');
        }
        $(".chat-logs").stop().animate({ scrollTop: $(".chat-logs")[0].scrollHeight}, 1000);
    }

    function generate_button_message(msg, buttons){
        /* Buttons should be object array
          [
            {
              name: 'Existing User',
              value: 'existing'
            },
            {
              name: 'New User',
              value: 'new'
            }
          ]
        */
        INDEX++;
        var btn_obj = buttons.map(function(button) {
            return  "              <li class=\"button\"><a href=\"javascript:;\" class=\"btn btn-primary chat-btn\" chat-value=\""+button.value+"\">"+button.name+"<\/a><\/li>";
        }).join('');
        var str="";
        str += "<div id='cm-msg-"+INDEX+"' class=\"chat-msg user\">";
        str += "          <span class=\"msg-avatar\">";
        str += "            <img src=\"https:\/\/bulk-jo.com\/images\/logo-sm.png\">";
        str += "          <\/span>";
        str += "          <div class=\"cm-msg-text\">";
        str += msg;
        str += "          <\/div>";
        str += "          <div class=\"cm-msg-button\">";
        str += "            <ul>";
        str += btn_obj;
        str += "            <\/ul>";
        str += "          <\/div>";
        str += "        <\/div>";
        $(".chat-logs").append(str);
        $("#cm-msg-"+INDEX).hide().fadeIn(300);
        $(".chat-logs").stop().animate({ scrollTop: $(".chat-logs")[0].scrollHeight}, 1000);
        $("#chat-input").attr("disabled", true);
    }

    $(document).delegate(".chat-btn", "click", function() {
        //var value = $(this).attr("chat-value");
        //var name = $(this).html();
       // $("#chat-input").attr("disabled", false);
        //generate_message(name, 'self');
    })

    $("#chat-circle").click(function() {
        $("#chat-circle").toggle('scale');
        $(".chat-box").toggle('scale');
        $(".chat-logs").stop().animate({ scrollTop: $(".chat-logs")[0].scrollHeight}, 1000);
    })

    $("#chat-circle-header").click(function() {
        $("#chat-circle").toggle('scale');
        $(".chat-box").toggle('scale');
        $(".chat-logs").stop().animate({ scrollTop: $(".chat-logs")[0].scrollHeight}, 1000);
    })

    $("#chat-circle-mobile").click(function() {
        $(".chat-box").toggle('scale');
        $(".chat-logs").stop().animate({ scrollTop: $(".chat-logs")[0].scrollHeight}, 1000);
    })

    $(".chat-box-toggle").click(function() {
        $("#chat-circle").toggle('scale');
        $(".chat-box").toggle('scale');
    })

    
    window.datatable_init = function(){
        var groupColumn = 1;
        $('.dataTable').each((_, element) => {
          dataTables.push($(element).DataTable({
            columnDefs: [
                { visible: false, targets: groupColumn }
            ],
            order: [[ groupColumn, 'asc' ]],
            processing: true,
            serverSide: true,
            ajax: $(element).data('url'),
            pageLength: 50,
            dom:
              '<"d-flex justify-content-between align-items-center header-actions mx-1 row mt-75"' +
              '<"col-lg-12 col-xl-6" l>' +
              '<"col-lg-12 col-xl-6 pl-xl-75 pl-0"<"dt-action-buttons text-xl-right text-lg-left text-md-right text-left d-flex align-items-center justify-content-lg-end align-items-center flex-sm-nowrap flex-wrap mr-1"<"mr-1"f>B>>' +
              '>t' +
              '<"d-flex justify-content-between mx-2 row mb-1"' +
              '<"col-sm-12 col-md-6"i>' +
              '<"col-sm-12 col-md-6"p>' +
              '>',
              buttons: [
    
              ],
              columnDefs: [
                 { orderable: false, targets: 'no-sort' }
              ],
              responsive: {
                details: {
                  display: $.fn.dataTable.Responsive.display.modal({
                    header: function (row) {
                      var data = row.data();
                      return  data[0];
                    }
                  }),
                  type: 'column',
                  renderer: $.fn.dataTable.Responsive.renderer.tableAll({
                    tableClass: 'table',
                    columnDefs: [
                      {
                        targets: 2,
                        visible: false
                      },
                      {
                        targets: 3,
                        visible: false
                      }
                    ]
                  })
                }
              }
          }));
          if (typeof init !== 'undefined' && $.isFunction(init)) {
            init();
          }
        });
    }

    var mySwiper14 = new Swiper('.swiper-responsive-breakpoints', {
        slidesPerView: 5,
        spaceBetween: 50,
        // init: false,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        breakpoints: {
            1024: {
                slidesPerView: 2,
                spaceBetween: 40
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 10
            }
        }
    });